var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-0 mt-1", attrs: { flat: "" } },
    [
      _c("v-card-text", { staticClass: "pa-0" }, [
        _vm.projectEquipmentObj.isProjectEquipment
          ? _c(
              "h3",
              { key: _vm.uniqueKey, staticClass: "pa-1 pl-2" },
              [
                _vm._v(_vm._s(_vm.$t("message.projects.project")) + ": "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/projects/" + _vm.projectEquipmentObj.projectId,
                    },
                  },
                  [_vm._v(_vm._s(_vm.projectTitle))]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.renderScrollList
        ? _c("scroll-list", {
            attrs: {
              id: "equipmentScrollList",
              payload: _vm.scrollListPayload,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }